<template>
  <LayoutBuyer hideTopBar :sidebarActiveItem="{ value: 'order' }">
    <div class="p-0 p-md-5 overflow-hidden">
      <AppLoading v-if="!quote || !order" fillSpace></AppLoading>
      <div v-else class="order-view m-auto">
        <GoBackBtn persistText>Back</GoBackBtn>
        <!-- Vendor Detail Row -->
        <div class="row p-3 p-md-0">
          <div class="col-3 col-md-1">
            <AppMediaViewer
              :src="quote.user.information.profile_img"
              width="100%"
              aspectRatio="1/1"
              class="rounded-circle"
            ></AppMediaViewer>
          </div>
          <div class="col-9 col-md-11 text-uppercase">
            <p class="m-0 text-gray text-caption">VENDOR</p>
            <h2>
              <span>{{ quote.user | fullName }} </span>
              <span class="ml-2 text-gray">{{ quote.user | companyName }}</span>
            </h2>
          </div>
        </div>

        <!-- Order Detail Row -->
        <div class="mt-md-2 p-3 p-md-0">
          <div class="row">
            <!-- RFQ ID -->
            <div class="col-6 col-md-2">
              <AppTextLabeled label="RFQ ID" class="font-weight-5">
                #{{ order.id | rfqFormat }}
              </AppTextLabeled>
            </div>
            <!-- PO Number -->
            <div class="col-6 col-md-2">
              <AppTextLabeled label="PO NO." class="font-weight-5">
                {{ order.po_number || 'N/A' }}
              </AppTextLabeled>
            </div>
            <!-- Project Name -->
            <div class="col-12 col-md mt-2 mt-md-0">
              <AppTextLabeled label="PROJECT NAME" class="font-weight-5">
                {{ order.project_name }}
              </AppTextLabeled>
            </div>
            <!-- Status -->
            <div class="col-12 col-md mb-2 mb-md-0 mt-2 mt-md-0">
              <AppTextLabeled label="STATUS" class="font-weight-4">
                <span
                  v-if="
                    quote.status === STATUS.QUOTE_APPROVED.quote_value ||
                    quote.status === STATUS.COMPLETED.quote_value
                  "
                  class="
                    m-0
                    p-1
                    bg-success-dark-2
                    text-white text-caption
                    rounded-sm
                    d-inline-block
                  "
                >
                  <AppIcon
                    name="checkmark"
                    class="mr-2 d-inline-block"
                  ></AppIcon>
                  {{
                    quote.status === STATUS.COMPLETED.quote_value
                      ? STATUS.COMPLETED.vendor_text
                      : STATUS.QUOTE_APPROVED.vendor_text
                  }}
                </span>
                <span
                  v-else
                  class="
                    m-0
                    p-1
                    bg-special-light-3
                    text-caption
                    rounded-sm
                    d-inline-block
                  "
                >
                  <AppIcon
                    name="checkmark"
                    class="mr-2 d-inline-block"
                  ></AppIcon>
                  <span class="d-inline-block">Request Approved</span>
                </span>
              </AppTextLabeled>
            </div>
            <!-- Order Date -->
            <div class="col-6 col-md">
              <AppTextLabeled label="Order Date" class="font-weight-5">
                {{ order.created_at | readableTime }}
              </AppTextLabeled>
            </div>
            <!-- Delivery Date -->
            <div class="col-6 col-md">
              <AppTextLabeled label="Delivery Date" class="font-weight-5">
                {{
                  (quote.delivery_date || order.delivery_date)
                    | readableDate(false, true)
                }}
              </AppTextLabeled>
            </div>
          </div>

          <div class="row mt-3">
            <!-- Delivery Option -->
            <div class="col-12 col-md-2">
              <AppTextLabeled label="DELIVERY OPTION" class="font-weight-5">
                {{
                  order.delivery_type === 'pick up'
                    ? 'Pick up'
                    : 'Ship to Address'
                }}
              </AppTextLabeled>
            </div>
            <!-- Address -->
            <div class="col-12 col-md mb-2 mb-md-0 mt-2 mt-md-0">
              <AppTextLabeled label="ADDRESS" class="font-weight-5">{{
                `${order.address}, ${order.city}, ${order.state} ${order.zip_code}`
              }}</AppTextLabeled>
            </div>
            <!-- Truck -->
            <div class="col-12 col-md">
              <AppTextLabeled label="TRUCK" class="font-weight-5">
                <span v-if="order.has_forklift">Piggy back forklift</span>
                <span v-if="order.is_union_delivery"> | Union Delivery</span>
              </AppTextLabeled>
            </div>
          </div>
        </div>

        <div class="row mt-3 p-3 p-md-0">
          <!-- Delivery Note -->
          <div class="col">
            <AppTextLabeled
              label="DELIVERY NOTE"
              class="font-weight-5 hoverable"
              @click="deliveryNotefull = true"
            >
              {{ order.delivery_note | truncateText(350) }}
            </AppTextLabeled>

            <AppModal v-model="deliveryNotefull" width="600px">
              <div class="p-2">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>DELIVERY NOTE</h4>
                  <AppIcon
                    hoverable
                    name="close"
                    @click="deliveryNotefull = false"
                  ></AppIcon>
                </div>

                <p class="mt-3">
                  {{ order.delivery_note || 'N/A' }}
                </p>

                <AppBtn
                  class="d-block mt-2 ml-auto mr-auto"
                  @click="deliveryNotefull = false"
                >
                  Close
                </AppBtn>
              </div>
            </AppModal>
          </div>
        </div>

        <!-- Product Table -->
        <div class="mt-5 mb-5">
          <AppBtn
            class="mb-2"
            :outline="!showPrices"
            color="secondary"
            @click="showPrices = !showPrices"
          >
            {{ showPrices ? 'Hide' : 'Show' }} Prices
          </AppBtn>
          <!--Header  -->
          <div class="bg-primary d-none d-md-block">
            <div class="row">
              <div :class="showPrices ? 'col-3' : 'col-5'">
                <p class="m-2 pl-2">Product</p>
              </div>
              <div :class="showPrices ? 'col-3' : 'col-4'">
                <p class="mt-2">Variant</p>
              </div>
              <div class="col-2"><p class="mt-2">Qty</p></div>
              <div v-if="showPrices" class="col-2">
                <p class="m-2">Price</p>
              </div>
              <div v-if="showPrices" class="col-2">
                <p class="m-2">Total</p>
              </div>
            </div>
          </div>
          <h6 class="p-2 text-white bg-gray-dark-5 d-md-none">PROPOSAL</h6>
          <!-- Body -->
          <div
            v-for="(product, index) in quote.products"
            :key="`product-item-${index}`"
            class="p-2 mb-3 shadow-40"
          >
            <!-- Web View -->
            <div class="row d-none d-md-flex">
              <div :class="showPrices ? 'col-3' : 'col-5'">
                <div class="row p-1">
                  <div class="col-3">
                    <AppMediaViewer
                      aspect-ratio="1/1"
                      :src="product.product | thumbnail"
                      width="50px"
                    ></AppMediaViewer>
                  </div>
                  <div class="col-9">
                    <p>{{ product.product.name || product.name }}</p>
                  </div>
                </div>

                <AppBtn
                  text
                  class="mt-1 p-0 text-d"
                  @click="viewAttachments(product.product.files)"
                >
                  <AppIcon name="document" class="d-inline"></AppIcon>
                  <span>{{ product.product.files.length }} Attached File</span>
                </AppBtn>
              </div>
              <div class="d-flex mt-2" :class="showPrices ? 'col-3' : 'col-4'">
                <span>{{
                  product.variant ? product.variant.name : 'N/A'
                }}</span>
              </div>
              <div class="col-2 d-flex mt-2">
                <div v-if="product.quantity_description">
                  <span>
                    {{
                      `${product.quantity} ${
                        product.quantity_description.quantity
                          ? product.quantity_description.quantity.name
                          : product.quantity_description.name
                      }`
                    }}
                  </span>
                  <span class="d-inline-block text-caption"
                    >({{
                      `${
                        parseInt(product.quantity) * product.conversion
                      } pieces`
                    }})</span
                  >
                </div>
                <span v-else>{{ `${product.quantity} pcs` }}</span>
              </div>
              <div v-if="showPrices" class="col-2 d-flex mt-2">
                <span>$ {{ product.price }}</span>
              </div>
              <div class="col-2 d-flex mt-2">
                <span v-if="showPrices" class="font-weight-bold"
                  >$ {{ product.total_price }}</span
                >
              </div>
            </div>

            <!-- Mobile View -->
            <div class="d-md-none p-1">
              <div class="mt-2">
                <div class="d-flex">
                  <!-- Details -->
                  <div class="mr-2">
                    <AppMediaViewer
                      width="42px"
                      :src="product.product | thumbnail"
                    ></AppMediaViewer>
                  </div>
                  <div class="row">
                    <div class="col-12 d-flex align-items-center">
                      <span class="text-body-2 font-weight-5 text-truncate">
                        {{ product.product.name || product.name }}
                      </span>
                    </div>
                    <div class="col-12 d-flex align-items-center">
                      <p class="m-0">
                        {{
                          product.variant ? product.variant.name : 'No Variant'
                        }}
                      </p>
                    </div>
                    <div class="col-6 d-flex align-items-center">
                      <p v-if="showPrices" class="m-0">$ {{ product.price }}</p>
                    </div>
                    <div
                      class="
                        col-6
                        d-flex
                        align-items-center
                        justify-content-end
                      "
                    >
                      <p class="text-truncate m-0">
                        x {{ `${product.quantity} pcs` }}
                      </p>
                    </div>
                    <div
                      class="
                        mt-1
                        col-12
                        d-flex
                        align-items-center
                        justify-content-end
                      "
                    >
                      <p v-if="showPrices" class="text-truncate m-0">
                        <span>SUBTOTAL: </span>
                        <span class="font-weight-6">
                          $
                          {{ product.price * product.quantity }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Attach File -->
                <div
                  v-if="
                    product.product.files && product.product.files.length > 0
                  "
                  class="mt-auto mb-0 d-flex flex-column"
                >
                  <AppDivider class="mt-2 mt-2"></AppDivider>
                  <AppBtn
                    text
                    prependIcon="document_attachment"
                    color="dark"
                    class="pl-0 mr-auto text-body-3"
                    @click="viewAttachments(product.product.files)"
                  >
                    {{ product.product.files.length }} Attached File
                  </AppBtn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Order Total Expense -->
        <!-- <div class="order-view-total ml-auto p-2 p-md-0">
          <h4>TOTAL</h4>
          <div class="p-3 shadow-60">
            <div class="row mb-3">
              <div class="col-8 text-caption text-md-body font-weight-4">
                MATERIALS TOTAL AMOUNT
              </div>
              <div class="col-4 font-weight-4 text-right">
                $ {{ total.productTotal }}
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-8 text-caption text-md-body font-weight-4">
                <span>TOTAL DELIVERY FEE</span>
                <AppIcon
                  hoverable
                  class="d-inline"
                  :name="showDeliveryDetails ? 'caret_up' : 'caret_down'"
                  @click="showDeliveryDetails = !showDeliveryDetails"
                ></AppIcon>
              </div>
              <div class="col-4 font-weight-4 text-right">
                $ {{ total.totalDelivery }}
              </div>
            </div>

            <div v-if="showDeliveryDetails" class="pl-1 text-gray-dark-5">
              <div class="row mb-3">
                <div class="col-8 text-caption-2 text-md-body-2 font-weight-4">
                  SHIP COST
                </div>
                <div
                  class="
                    col-4
                    text-caption-2 text-md-body-2
                    font-weight-4
                    text-right
                  "
                >
                  $ {{ quote.shipping_address_fee }}
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-8 text-caption-2 text-md-body-2 font-weight-4">
                  TRUCK FEE
                </div>
                <div
                  class="
                    col-4
                    text-caption-2 text-md-body-2
                    font-weight-4
                    text-right
                  "
                >
                  $ {{ quote.truck_fee }}
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-8 text-caption text-md-body font-weight-4">TAX</div>
              <div class="col-4 font-weight-4 text-right">$ {{ total.tax }}</div>
            </div>

            <AppDivider class="mb-3"></AppDivider>

            <div class="row mb-3">
              <div class="col-8 text-caption text-md-body">TOTAL</div>
              <div class="col-4">
                <h4 class="font-style-primary text-right">$ {{ total.total }}</h4>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Action Btns -->
        <div
          v-if="quote.status === STATUS.QUOTE_APPROVED.quote_value"
          class="
            p-2 p-md-0
            mt-4
            d-flex
            flex-column flex-md-row
            justify-content-md-end
          "
        >
          <AppBtn
            :disabled="!quote.delivered_at"
            :loading="loading"
            @click="confirmReceive = true"
          >
            ORDER RECEIVED
          </AppBtn>
        </div>
        <div
          v-else-if="quote.status === STATUS.QUOTE_SENT.quote_value"
          class="
            p-2 p-md-0
            mt-4
            d-flex
            flex-column flex-md-row
            justify-content-md-end
          "
        >
          <AppBtn
            outline
            class="order-1 order-md-0"
            @click="confirmDecline = true"
          >
            DECLINE
          </AppBtn>
          <AppBtn class="mb-2 mb-md-0 ml-md-3" @click="confirmApprove = true">
            APPROVE QUOTATION
          </AppBtn>
        </div>
      </div>
      <!-- Attached files viewer -->
      <AttachedFileDrawer
        v-model="attachmentDrawer"
        :files="attachmentFiles"
      ></AttachedFileDrawer>

      <!-- Confirm Approval Modal -->
      <ActionModal
        v-if="quote && quote.status === STATUS.QUOTE_SENT.quote_value"
        v-model="confirmApprove"
      >
        <h5>APPROVE THIS QUOTE?</h5>
        <p>Please review the following before confirming</p>
        <div class="mt-3 w-75">
          <!-- Delivery Date -->
          <div class="row">
            <div class="col-6"><p>DELIVERY DATE</p></div>
            <div class="col-6" v-if="quote || order">
              {{
                quote.delivery_date ||
                order.delivery_date | readableDate(false, true)
              }}
            </div>
          </div>
          <!-- Subtotal -->
          <div class="row">
            <div class="col-6"><p>SUBTOTAL</p></div>
            <div class="col-6">$ {{ total.productTotal }}</div>
          </div>
          <!-- Delivery Fee -->
          <div class="row">
            <div class="col-6"><p>Delivery Fee</p></div>
            <div class="col-6">$ {{ total.totalDelivery }}</div>
          </div>
          <!-- Tax -->
          <div class="row">
            <div class="col-6"><p>TAX</p></div>
            <div class="col-6">$ {{ total.tax }}</div>
          </div>
          <AppDivider class="mb-2"></AppDivider>
          <!-- Tax -->
          <div class="row">
            <div class="col-6"><p>TOTAL</p></div>
            <div class="col-6">$ {{ total.total }}</div>
          </div>
        </div>
        <!-- Action buttons -->
        <div class="mt-2 w-75 d-flex">
          <AppBtn
            outline
            class="w-100"
            :loading="loading"
            @click="confirmApprove = false"
          >
            CANCEL
          </AppBtn>
          <AppBtn class="ml-2 w-100" :loading="loading" @click="approveQuote">
            APPROVE QUOTE
          </AppBtn>
        </div>
      </ActionModal>
      <!-- Success Approval Modal  -->
      <SuccessModal
        v-model="successApprove"
        @confirm="$router.push({ name: 'OrderAcceptedList' })"
      >
        <template v-slot:title> QUOTE APPROVED! </template>
        <template v-slot:description>
          You can now click "Confirm Receive" button once the product(s) has
          been shipped.
        </template>
        <template v-slot:action-btn-text> Close </template>
      </SuccessModal>

      <!-- Confirm Receive Modal -->
      <ActionModal
        v-if="quote && quote.status === STATUS.QUOTE_APPROVED.quote_value"
        v-model="confirmReceive"
        @confirm="receiveOrder"
      >
        <template v-slot:title> Confirm Receive </template>
        <template v-slot:description> Click confirm to proceed </template>
      </ActionModal>
      <!-- Success Receive Modal  -->
      <SuccessModal
        v-model="successReceive"
        @confirm="$router.push({ name: 'OrderCompletedList' })"
      >
        <template v-slot:title> PRODUCT DELIVERY CONFIRMED! </template>
        <template v-slot:description> Click close to proceed </template>
        <template v-slot:action-btn-text> Close </template>
      </SuccessModal>

      <!-- Confirm Decline Modal -->
      <ActionModal
        v-if="quote && quote.status === STATUS.QUOTE_SENT.quote_value"
        state="delete"
        v-model="confirmDecline"
        @confirm="declineQuote"
      >
        <template v-slot:title> Confirm Decline </template>
        <template v-slot:description> Click confirm to proceed </template>
      </ActionModal>
      <!-- Success Receive Modal  -->
      <SuccessModal
        v-model="successDecline"
        @confirm="$router.push({ name: 'OrderRejectList' })"
      >
        <template v-slot:title> QUOTE DECLINED! </template>
        <template v-slot:description></template>
        <template v-slot:action-btn-text> Close </template>
      </SuccessModal>
    </div>
  </LayoutBuyer>
</template>

<script>
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppTextLabeled from '@/shared/elements/AppTextLabeled.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppDivider from '@/shared/elements/AppDivider.vue';
import AttachedFileDrawer from '@/components/Partial/Orders/AttachedFileDrawer.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import OrderConstants from '@/shared/constants/OrderConstants';
import AppModal from '@/shared/elements/AppModal.vue';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import LayoutBuyer from '../../../../components/Partial/Layouts/LayoutBuyer/LayoutBuyer.vue';
export default {
  name: 'OrderQuoteView',

  components: {
    GoBackBtn,
    AppMediaViewer,
    AppTextLabeled,
    AppIcon,
    AppBtn,
    AppDivider,
    AttachedFileDrawer,
    ActionModal,
    AppLoading,
    AppModal,
    SuccessModal,
    LayoutBuyer,
  },

  mixins: [_rfqFormat, _readableDateTime, _appApiHelper],

  data() {
    return {
      STATUS: OrderConstants.STATUS,
      quote: null,
      order: null,
      showDeliveryDetails: false,
      confirmApprove: false,
      successApprove: false,
      confirmDecline: false,
      successDecline: false,
      confirmReceive: false,
      successReceive: false,
      attachmentDrawer: false,
      attachmentFiles: [],
      id: this.$route.params?.id,
      deliveryNotefull: false,
      showPrices: false,
    };
  },

  computed: {
    total() {
      let totalPrices = 0;

      if (this.quote?.products?.length > 0) {
        totalPrices = this.quote.products.reduce((total, product) => {
          return total + product.total_price;
        }, 0);
      }
      const tax = 0;

      return {
        productTotal: totalPrices,
        totalDelivery: this.quote?.shipping_address_fee + this.quote?.truck_fee,
        tax,
        total:
          totalPrices +
          this.quote?.shipping_address_fee +
          this.quote?.truck_fee +
          tax,
      };
    },
  },

  filters: {
    fullName(user) {
      if (user?.information?.first_name) {
        return `${user.information.first_name} ${user.information.last_name}`;
      } else {
        return '';
      }
    },
    companyName(user) {
      if (user?.information) {
        return user.information?.company_name || user.information?.company;
      }

      return 'UNKNOWN SUPPLIER';
    },
    thumbnail(value) {
      if (value && value?.files?.length > 0) {
        const files = value.files;
        let thumb = value.files.find((file) => file.type === 'primary_image');
        return thumb?.filename || files[0]?.filename;
      } else {
        return null;
      }
    },
    truncateText(value, maxChars = 100) {
      if (!value) {
        return 'N/A';
      } else if (value.length <= maxChars || maxChars <= 0) {
        return value;
      } else {
        return `${value.slice(0, maxChars + 1)}...`;
      }
    },
  },

  methods: {
    viewAttachments(attachments) {
      this.attachmentFiles = attachments;
      this.attachmentDrawer = true;
    },
    async fetchQuote() {
      const apiResponse = await Orders.getQuote(this.id);
      this.quote = apiResponse.data;
      this.order = apiResponse.data.order;
    },
    showFileAttachments() {
      this.fileAttachmentDrawer = true;
    },
    async approveQuote() {
      const { STATUS } = OrderConstants;
      await Orders.editQuote(this.id, {
        status: STATUS.QUOTE_APPROVED.quote_value,
      });

      if (!this.error) {
        const response = await Orders.editOrder(this.order.id, {
          status: STATUS.QUOTE_APPROVED.value,
        });
        if (response.status === 200) {
          this.successApprove = true;
        }
      }

      this.confirmApprove = false;
    },
    async declineQuote() {
      const { STATUS } = OrderConstants;

      const response = await Orders.editQuote(this.id, {
        received: 1,
        status: STATUS.QUOTE_REJECTED.quote_value,
      });

      if (response.status === 200) {
        this.successDecline = true;
      }
    },
    async receiveOrder() {
      const { STATUS } = OrderConstants;
      await Orders.editOrder(this.order.id, {
        status: STATUS.COMPLETED.value,
      });

      const response = await Orders.editQuote(this.id, {
        received: 1,
        status: STATUS.COMPLETED.quote_value,
      });

      if (response.status === 200) {
        this.successReceive = true;
      }
    },
  },

  mounted() {
    this.fetchQuote();
  },
};
</script>

<style lang="scss" scoped>
.order-view {
  max-width: 1200px;

  &-total {
    max-width: 500px;
  }
}
</style>
